.logo{
  position: fixed;
  top: 2.5rem;
  left: 7rem;
}


.image1 {
  position: fixed;
  bottom: 10%;
  left: 50%;
z-index: 99999999999;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.icons {
  position: absolute;
  left: 15em ;
  top: 3rem;
  display: flex;
  flex-direction: row;
  transform-style: preserve-3d;
}

.icons li {
  position: relative;
  list-style: none;
  width: 40px;
  height: 40px;
  margin: 0px 20px;
}

.icons li::before {
  content: "";
  position: absolute;
  bottom: -7px;
  left: -2px;
  width: 100%;
  height: 10px;
  transform-origin: top;
  transform: skewX(-41deg);
}

.icons li::after {
  content: "";
  position: absolute;
  top: 1px;
  left: -9px;
  width: 9px;
  height: 100%;
  transform-origin: right;
  transform: skewY(-41deg);
}

.icons li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  background:#fafafa ;
  justify-content: center;
  align-items: center;
  font-size: 30px !important;
  transition: 1.5s ease-out;
  color: #073230 !important;
}

.icons li:hover span {
  z-index: 1000;
  transition: 0.3s;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
  color: white !important;
}

.icons li:hover span:nth-child(5) {
  transform: translate(25px, -25px);
  opacity: 1;
}
.icons li:hover span:nth-child(4) {
  transform: translate(19px, -19px);
  opacity: 0.8;
}
.icons li:hover span:nth-child(3) {
  transform: translate(13px, -13px);
  opacity: 0.6;
}
.icons li:hover span:nth-child(2) {
  transform: translate(7px, -7px);
  opacity: 0.4;
}
.icons li:hover span:nth-child(1) {
  transform: translate(0px, 0px);
  opacity: 0.2;
}

.icons li:nth-child(1):hover span {
  background: rgb(59 130 246) !important;
}
.icons li:nth-child(2):hover span {
  background: rgb(239 68 68) !important;
}
.icons li:nth-child(3):hover span {
  background: rgb(236 72 153) !important;
}
.icons li:nth-child(4):hover span {
  background: rgb(59 130 246) !important;
}

a {
  text-decoration: none;
  display: inline-block;
}

.btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.text {
  padding: 0 50px;
  visibility: hidden;
}

.flip-front,
.flip-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 150%;
  height: 100%;
  -webkit-transform-style: flat;
  transform-style: flat;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  background-color: black;
  color: white !important;
}

.flip-front {
  color: #073230 !important;
  border-radius: 50px;
  background-color: #fdd978;
  -webkit-transform: rotateX(0deg) translateZ(25px);
  -ms-transform: rotateX(0deg) translateZ(25px);
  -o-transform: rotateX(0deg) translateZ(25px);
  transform: rotateX(0deg) translateZ(25px);
}

.flip-back {
  color: #fff;
  border-radius: 50px;
  border: 2px solid white;
  -webkit-transform: rotateX(90deg) translateZ(25px);
  -ms-transform: rotateX(90deg) translateZ(25px);
  -o-transform: rotateX(90deg) translateZ(25px);
  transform: rotateX(90deg) translateZ(25px);
}

.btn:hover .flip-front {
  -webkit-transform: rotateX(-90deg) translateZ(25px);
  -ms-transform: rotateX(-90deg) translateZ(25px);
  -o-transform: rotateX(-90deg) translateZ(25px);
  transform: rotateX(-90deg) translateZ(25px);
}

.btn:hover .flip-back {
  -webkit-transform: rotateX(0deg) translateZ(25px);
  -ms-transform: rotateX(0deg) translateZ(25px);
  -o-transform: rotateX(0deg) translateZ(25px);
  transform: rotateX(0deg) translateZ(25px);
}

body {
  overflow-x: hidden !important;
}






@media screen and (max-width: 1000px) {
  .icons li {
    width: 60px;
    height: 60px;
    margin: 0px 20px;
    line-height: 5rem;
  }

  .icons {
    position: absolute;
    left: 12rem ;
    top: 3rem;
    display: flex;
    gap: 0px !important;
    flex-direction: row;
    transform-style: preserve-3d;
  }

  .logo{
    position: fixed;
    top: 2rem;
    left: 1rem;
  }

  .image1 {
    position: absolute;
    bottom: 50%;
    left: 20%;
  z-index: 999999999999999999999999999999999999999999999999;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
  }
  
}

@media screen and (max-width: 600px) {
  .icons li {
    width: 40px;
    height: 40px;
    margin: 0px 20px;
  }

  .icons {
    position: absolute;
    left: 5rem ;
    top: 2rem;
    display: flex;
    gap: 0px !important;
    flex-direction: row;
    transform-style: preserve-3d;
  }

  .image1 {
    position: absolute;
    bottom: 40%;
    left: 30%;
  z-index: 999999999999999999999999999999999999999999999999;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
  }

}

@media screen and (max-width: 281px) {
  .icons li {
    width: 40px;
    height: 40px;
    margin: 0px 10px;
  }
}



