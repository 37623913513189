.containerimg {
  display: flex;
  width: 100%;
  padding: 4% 2%;
  box-sizing: border-box;
  height: 80vh;
}

.box {
  flex: 1;
  overflow: hidden;
  transition: 0.5s;
  margin: 0 2%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  line-height: 0;
}

.pbox {
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.1);
}

.box > img {
  width: 200%;
  height: calc(100% - 10vh);
  object-fit: cover;
  transition: 0.5s;
}

.box > span {
  font-size: 3.8vh;
  display: block;
  text-align: center;
  height: 10vh;
  line-height: 2.6;
}

.box:hover {
  flex: 1 1 40%;
}
.box:hover > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

@media screen and (max-width: 1000px) {
  .containerimg {
    flex-direction: column;
  }
}
