.card {
  width: 3in;
  height: 5in;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  perspective: 2500px;
  margin:  10px;
}


.wrapper {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  z-index: -1;
}


.card:hover .wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  box-shadow: 2px 35px 32px -32px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 35px 32px -32px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -32px rgba(0, 0, 0, 0.75);
}


.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 200px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}


.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    transparent 16%,
    #fafafa 88%,
    #fafafa 28%
  );
}
.wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(
    to bottom,
    transparent 16%,
    #fafafa 88%,
    #fafafa 28%
  );
}

.card:hover .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.card:hover .wrapper::after {
  height: 400px;
}
.title {
  width: 100%;
  transition: transform 0.5s;
  margin-bottom: 15px;
  text-align: center;
  font-size: 22px;
}
.card:hover .title {
  transform: translate3d(0%, -50px, 100px);
}

.character {
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  z-index: -1;
}

.card:hover .character {
  opacity: 1;
  transform: translate3d(0%, -30%, 100px);
}



@media screen and (max-width: 900px) {
.card {
  width: 3in;
  height: 4in;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  perspective: 2500px;
  margin:  10px;
}
}
