@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Serif Text', serif;
  scroll-behavior: smooth;
  color-scheme: dark;
}



@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500&family=Petemoss&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Galdeano&display=swap');

@import "~swiper/swiper-bundle.min.css";



html,
body {
  scroll-behavior: smooth;
}

@media only screen and (max-width: 850px) {
  html,
  body {
    overflow-x: hidden !important;
  }
}





#createProductFormImage {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

#createProductFormImage > img {
  width: 5vmax;

  margin: 0 0.5vmax;
}

.content {
  font-family: 'Galdeano';
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.container {
  max-width: 124rem;
  margin: 0 auto;
}

.swiper_container {
  height: 40rem;

  position: relative;
}

.swiper-slide {
  position: relative;
}

@media (min-width: 1200px) {
  .swiper-slide {
    width: 30rem;
  }
}

@media only screen and (max-width: 850px) and (min-width: 701px) {
  .swiper-slide {
    max-width: 30rem;
  }

  #testMonials .swiper-slide {
    max-width: 52rem;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 851px) {
  .swiper-slide {
    max-width: 25rem;
  }
  #testMonials .swiper-slide {
    max-width: 65rem;
  }
}

@media (max-width: 700px) {
  .swiper-slide {
    width: 100%;
    max-width: 24rem;
  }

  #client .swiper-slide {
    width: 100%;
    max-width: 50rem;
  }

  #testMonials .swiper-slide {
    width: 100%;
    max-width: 50rem;
  }
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}


.hero1{
  
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  /* height:100vh; */
  background-color:#efecda;
  background-position:center
}

.hero1:after{
  content:"";
  background-image:url("https://upload.wikimedia.org/wikipedia/commons/7/76/1k_Dissolve_Noise_Texture.png");
  height: 300%;
  width: 300%;
  position: fixed;
  opacity:0.1;
   animation: animateGrain1 8s steps(10) infinite;
}

@keyframes animateGrain1{
  0%, 100% { transform:translate(0, 0) }
  10%{
    transform:translate(-5%,-10%)
  }
  20%{
    transform:translate(-15%,-20%)
  }
  30%{
    transform:translate(-5%,-10%)
  }
  40%{
    transform:translate(-15%,-20%)
  }
  
  50%{
    transform:translate(-5%,-10%)
  }
  60%{
    transform:translate(-15%,-20%)
  }
  70%{
    transform:translate(-5%,-10%)
  }
  80%{
    transform:translate(-15%,-20%)
  }
  90%{
    transform:translate(-5%,-10%)
  }
  100%{
    transform:translate(-15%,-20%)
  }
 
}