@import url("https://fonts.googleapis.com/css?family=Montserrat:700");

.textvideo {
  background-color: white;
  background-image: radial-gradient(#ffffff, #ffffff);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: black;
  text-align: center;
  width: 100%;
  height: 106vh !important;
  font-weight: 700;
  overflow: hidden !important;
  font-family: "Montserrat", sans-serif !important;
}

/* .hero-image {
  background-image: url("https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687001618/Untitled_design_59_irfr80.png");
  background-color: #fafafa; 
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover;
} */

.vimg {
  margin: -5vh auto !important;
}

#fly-in {
  font-size: 10em;
  margin: 30vh auto;
  text-transform: uppercase;
  overflow: hidden !important;
  background-color: transparent;
}

#fly-in span {
  display: block;
  font-size: 0.4em;
  opacity: 0.8;
  overflow: hidden !important;
}

#fly-in div {
  position: absolute;
  margin: 2vh 0px;
  opacity: 0;
  left: 10%;
  width: 80vw;
  animation: switch 30s linear infinite;
  overflow: hidden !important;
}

#fly-in div:nth-child(2) {
  animation-delay: 1s;
}
#fly-in div:nth-child(3) {
  animation-delay: 2s;
}
#fly-in div:nth-child(4) {
  animation-delay: 3s;
}
#fly-in div:nth-child(5) {
  animation-delay: 4s;
}
#fly-in div:nth-child(6) {
  animation-delay: 5s;
}
#fly-in div:nth-child(7) {
  animation-delay: 6s;
}
#fly-in div:nth-child(8) {
  animation-delay: 7s;
}
#fly-in div:nth-child(9) {
  animation-delay: 8s;
}
#fly-in div:nth-child(10) {
  animation-delay: 9s;
}
#fly-in div:nth-child(11) {
  animation-delay: 10s;
}
#fly-in div:nth-child(12) {
  animation-delay: 11s;
}
#fly-in div:nth-child(13) {
  animation-delay: 12s;
}
#fly-in div:nth-child(14) {
  animation-delay: 13s;
}
#fly-in div:nth-child(15) {
  animation-delay: 14s;
}
#fly-in div:nth-child(16) {
  animation-delay: 15s;
}
#fly-in div:nth-child(17) {
  animation-delay: 16s;
}
#fly-in div:nth-child(18) {
  animation-delay: 17s;
}
#fly-in div:nth-child(19) {
  animation-delay: 18s;
}
#fly-in div:nth-child(20) {
  animation-delay: 19s;
}
#fly-in div:nth-child(21) {
  animation-delay: 20s;
}
#fly-in div:nth-child(22) {
  animation-delay: 21s;
}
#fly-in div:nth-child(23) {
  animation-delay: 22s;
}
#fly-in div:nth-child(24) {
  animation-delay: 23s;
}
#fly-in div:nth-child(25) {
  animation-delay: 24s;
}
#fly-in div:nth-child(26) {
  animation-delay: 25s;
}
#fly-in div:nth-child(27) {
  animation-delay: 26s;
}
#fly-in div:nth-child(28) {
  animation-delay: 27s;
}
#fly-in div:nth-child(29) {
  animation-delay: 28s;
}

@keyframes switch {
  0% {
    opacity: 0;
    filter: blur(20px);
    transform: scale(2);
  }
  2% {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
  5% {
    opacity: 0;
    filter: blur(0);
    transform: scale(0.9);
  }
  10% {
    opacity: 0;
    filter: blur(10px);
    transform: scale(0.1);
  }
  100% {
    opacity: 0;
  }
}

.homechange {
  min-height: 106vh;
  background: #556270;

  animation: b 58s infinite;
}

@keyframes b {
  0%,
  13% {
    background: radial-gradient(circle, #fafafa, #fafafa);
  }
  14%,
  19% {
    background: radial-gradient(circle, #fafafa, #fafafa);
  }
  31%,
  36% {
    background: radial-gradient(circle, #fafafa, #fafafa);
  }
  45%,
  55% {
    background: radial-gradient(circle, #fafafa, #fafafa);
  }
  64%,
  69% {
    background: radial-gradient(circle, #fafafa, #fafafa);
  }
  81%,
  86% {
    background: radial-gradient(circle, #fafafa, #fafafa);
  }
  97%,
  to {
    background: radial-gradient(circle, #fafafa, #fafafa);
  }
}

@media screen and (max-width: 1000px) {
  .textvideo {
    height: 65vh !important;
    padding: auto 2vw;
  }

  .homechange {
    margin: 0;
    min-height: 65vh;
    background: #556270;

    animation: b 9s infinite;
  }

  #fly-in {
    font-size: 10em;
    margin: 0.7em auto;
    text-transform: uppercase;
  }

  .seconddiv {
    margin: -0.5em auto !important;
  }

  .vimg {
    margin: auto !important;
  }
}

@media screen and (max-width: 680px) {
  .textvideo {
    height: 50vh !important;
  }

  .homechange {
    margin: 0;
    min-height: 50vh;
    background: #556270;

    animation: b 9s infinite;
  }

  #fly-in {
    font-size: 4.6em;
    margin: 8vh 0px;
    text-transform: uppercase;
  }

  .vimg {
    margin: -0.5em auto !important;
  }
}

@media screen and (max-width: 370px) {
  .textvideo {
    height: 50vh !important;
  }

  .homechange {
    margin: 0;
    min-height: 50vh;
    background: #556270;

    animation: b 9s infinite;
  }

  #fly-in {
    font-size: 4em;
    margin: 6vh 0px;
    text-transform: uppercase;
  }

  .vimg {
    margin: 0vh auto !important;
  }
}
